export interface JiraIssue {
  expand: string
  id: string
  self: string
  key: string
  fields: JiraIssueFields
  renderedFields?: JiraIssueFields
}

export interface JiraIssueFields {
  statuscategorychangedate: string
  fixVersions: Array<any>
  resolution: JiraIssueResolution
  customfield_10630: Array<any>
  customfield_10114: any
  customfield_10500: Array<any>
  customfield_10621: any
  customfield_10501: any
  customfield_10622: any
  customfield_10623: any
  customfield_10502: any
  customfield_10624: any
  customfield_10503: any
  customfield_10504: Customfield10504
  customfield_10625: any
  customfield_10505: Customfield10505
  lastViewed: any
  priority: Priority
  customfield_10100: string
  customfield_10101: string
  customfield_10102: any
  labels: Array<any>
  customfield_10620: any
  customfield_10103: Customfield10103
  customfield_10612: any
  customfield_10613: any
  aggregatetimeoriginalestimate: any
  timeestimate: any
  customfield_10614: any
  customfield_10615: any
  versions: Array<any>
  customfield_10616: any
  customfield_10617: any
  customfield_10618: Customfield10618
  issuelinks: Array<any>
  assignee: Assignee
  status: Status
  components: Array<JiraIssueComponent>
  customfield_10203: any
  aggregatetimeestimate: any
  creator: Creator
  subtasks: Array<any>
  reporter: JiraReporter | null
  aggregateprogress: Aggregateprogress
  customfield_10200: any
  customfield_10201: any
  customfield_10202: any
  progress: Progress
  votes: Votes
  worklog: Worklog
  issuetype: Issuetype
  timespent: any
  project: Project
  aggregatetimespent: any
  resolutiondate: string
  workratio: number
  watches: Watches
  issuerestriction: Issuerestriction
  created: string
  customfield_10300: any
  customfield_10663: any
  updated: string
  timeoriginalestimate: any
  description: string
  customfield_10650: any
  customfield_10651: any
  customfield_10652: any
  customfield_10653: any
  timetracking: Timetracking
  customfield_10643: any
  customfield_10005: any
  customfield_10644: any
  security: any
  customfield_10645: any
  customfield_10646: any
  attachment: Array<JiraAttachment>
  customfield_10647: any
  customfield_10648: any
  customfield_10649: any
  summary: string
  customfield_10001: Array<Customfield10001>
  customfield_10640: any
  customfield_10641: any
  customfield_10642: any
  /** Request Type */
  customfield_10400?: Customfield10400
  customfield_10115: any
  customfield_10632: any
  customfield_10633: any
  customfield_10116: string
  environment: any
  customfield_10635: any
  customfield_10636: any
  customfield_10637: any
  duedate: any
  customfield_10638: any
  comment?: JiraIssueCommentData
}

export interface JiraAttachment {
  self: string
  id: string
  filename: string
  author: Author
  created: string
  size: string
  mimeType: string
  content: string
  thumbnail: string
}

export interface JiraReporter {
  self: string
  accountId: string
  emailAddress: string
  avatarUrls: AvatarUrls
  displayName: string
  active: boolean
  timeZone: string
  accountType: string
}

export interface JiraIssueResolution {
  self: string
  id: string
  description: string
  name: string
}

export interface Customfield10400 {
  requestType?: RequestType
  currentStatus: CurrentStatus
}


export interface RequestType {
  id: string
  name: string
  description: string
  helpText: string
  issueTypeId: string
  serviceDeskId: string
  portalId: string
  groupIds: Array<string>
}

export interface CurrentStatus {
  status: string
  statusCategory: string
  statusDate: StatusDate
}

export interface StatusDate {
  iso8601: string
  jira: string
  friendly: string
  epochMillis: number
}


export interface Customfield10504 {
  id: string
  name: string
  _links: Links
  completedCycles: Array<CompletedCycle>
}

export interface Links {
  self: string
}

export interface CompletedCycle {
  startTime: StartTime
  stopTime: StopTime
  breachTime: BreachTime
  breached: boolean
  goalDuration: GoalDuration
  elapsedTime: ElapsedTime
  remainingTime: RemainingTime
}

export interface StartTime {
  iso8601: string
  jira: string
  friendly: string
  epochMillis: number
}

export interface StopTime {
  iso8601: string
  jira: string
  friendly: string
  epochMillis: number
}

export interface BreachTime {
  iso8601: string
  jira: string
  friendly: string
  epochMillis: number
}

export interface GoalDuration {
  millis: number
  friendly: string
}

export interface ElapsedTime {
  millis: number
  friendly: string
}

export interface RemainingTime {
  millis: number
  friendly: string
}

export interface Customfield10505 {
  id: string
  name: string
  _links: Links
  completedCycles: Array<CompletedCycle>
}

export interface Priority {
  self: string
  iconUrl: string
  name: string
  id: string
}

export interface Customfield10103 {
  hasEpicLinkFieldDependency: boolean
  showField: boolean
  nonEditableReason: NonEditableReason
}

export interface NonEditableReason {
  reason: string
  message: string
}

export interface Customfield10618 {
  languageCode: string
  displayName: string
}

export interface Assignee {
  self: string
  accountId: string
  avatarUrls: AvatarUrls
  displayName: string
  active: boolean
  timeZone: string
  accountType: string
}

export interface AvatarUrls {
  "48x48": string
  "24x24": string
  "16x16": string
  "32x32": string
}

export interface Status {
  self: string
  description: string
  iconUrl: string
  name: string
  id: string
  statusCategory: StatusCategory
}

export interface StatusCategory {
  self: string
  id: number
  key: string
  colorName: string
  name: string
}

export interface JiraIssueComponent {
  self: string
  id: string
  name: string
  description: string
}

export interface Creator {
  self: string
  accountId: string
  avatarUrls: AvatarUrls
  displayName: string
  active: boolean
  timeZone: string
  accountType: string
}

export interface Aggregateprogress {
  progress: number
  total: number
}

export interface Progress {
  progress: number
  total: number
}

export interface Votes {
  self: string
  votes: number
  hasVoted: boolean
}

export interface Worklog {
  startAt: number
  maxResults: number
  total: number
  worklogs: Array<any>
}

export interface Issuetype {
  self: string
  id: string
  description: string
  iconUrl: string
  name: string
  subtask: boolean
  avatarId: number
  hierarchyLevel: number
}

export interface Project {
  self: string
  id: string
  key: string
  name: string
  projectTypeKey: string
  simplified: boolean
  avatarUrls: AvatarUrls
}

export interface Watches {
  self: string
  watchCount: number
  isWatching: boolean
}

export interface Issuerestriction {
  issuerestrictions: Issuerestrictions
  shouldDisplay: boolean
}

export interface Issuerestrictions {}

export interface Timetracking {}

export interface Customfield10001 {
  id: string
  name: string
  _links: Links3
}

export interface Links3 {
  self: string
}

export interface JiraIssueCommentData {
  comments: Array<JiraComment>
  self: string
  maxResults: number
  total: number
  startAt: number
}

export interface JiraTime {
  iso8601: string
  jira: string
  friendly: string
  epochMillis: number
}

export interface JiraSupportComment {
  id: string
  body: string
  renderedBody?: { html: string }
  public: boolean
  author: Author
  created: JiraTime
}

export interface JiraComment {
  self: string
  id: string
  author: Author
  body: string
  updateAuthor: UpdateAuthor
  created: string
  updated: string
  jsdPublic: boolean
}

export interface Author {
  self: string
  accountId: string
  avatarUrls: AvatarUrls
  displayName: string
  active: boolean
  timeZone: string
  accountType: string
}

export interface UpdateAuthor {
  self: string
  accountId: string
  avatarUrls: AvatarUrls
  displayName: string
  active: boolean
  timeZone: string
  accountType: string
}

export interface CreateJiraIssuePayload {
  summary: string
  description: string
  category: string
}

export const useSupportStore = defineStore("support", () => {
  const getIssues = async () => {
    const { data } = await useSamApi<Array<JiraIssue>>("/support/issues")
    return data
  }

  const getIssue = async (issueKey: string) => {
    const { data } = await useSamApi<JiraIssue>(`/support/issues/${issueKey}`)
    return data
  }

  const createIssue = async (payload: CreateJiraIssuePayload) => {
    const { data } = await useSamApi<JiraIssue>("/support/issues", {
      method: "POST",
      body: JSON.stringify(payload),
    })
    return data
  }

  const resolveIssue = async (issueKey: string, comment: string) => {
    await useSamApi(`/support/issues/${issueKey}/resolve`, {
      method: "POST",
      body: JSON.stringify({ comment }),
    })
  }

  const addAttachment = async (issueKey: string, files: Array<File>) => {
    const formData = new FormData()
    files.forEach((file) => { formData.append("files", file) })

    await useSamApi(`/support/issues/${issueKey}/attachments`, {
      method: "POST",
      body: formData,
    }, { forceNoContentType: true })
  }

  const getComments = async (issueKey: string) => {
    const { data } = await useSamApi<Array<JiraSupportComment>>(`/support/issues/${issueKey}/comments`)
    return data
  }

  const addComment = async (issueKey: string, comment: string) => {
    await useSamApi(`/support/issues/${issueKey}/comments`, {
      method: "POST",
      body: JSON.stringify({ comment }),
    })
  }

  return {
    getIssues,
    getIssue,
    createIssue,
    resolveIssue,

    addAttachment,

    getComments,
    addComment,
  }
})